




























import { Component, Vue, Prop } from 'vue-property-decorator'

import UserImage from '@/components/user/UserImage.vue'
import SearchInput from '@/components/inputs/Search.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

import { UserResource } from '@/models/users/UserResource'
import { AttachUsersRequest } from '@/requests/implementations/AttachUsersRequest'
import { EntityResource } from '@/models/implementations/entities/EntityResource'

@Component({
  components: {
    UserImage,
    SmallLoader,
    SearchInput
  }
})
export default class AttachUsers extends Vue {

  @Prop()
  private entity!: EntityResource

  private isFetchingData: boolean = false
  private isSubmitting: boolean = false
  private availableUsers: UserResource[] = []
  private search: string = ''

  private form: AttachUsersRequest = new AttachUsersRequest()

  private get canSubmitForm(): boolean {
    return this.form.user_ids.length > 0 && !this.isSubmitting
  }

  private get filteredUsers(): UserResource[] {
    return this.availableUsers.filter((user) => user.name.toLowerCase().includes(this.search.toLowerCase()))
  }

  private get hasData(): boolean {
    return this.availableUsers.length > 0
  }

  private get hasFilteredUsers(): boolean {
    return this.availableUsers.length > 0
  }

  private created(): void {
    this.form = new AttachUsersRequest()
    this.getAvailableUsers()
  }

  private async getAvailableUsers(): Promise<void> {
    this.isFetchingData = true
    try {
      const { data } = await this.entity.getAvailableUsers()
      this.availableUsers = data
    } finally {
      this.isFetchingData = false
    }
  }

  private toggleUser(user: UserResource): void {
    const index = this.form.user_ids.findIndex((id) => id === user.id)
    if (index > -1) {
      this.form.user_ids.splice(index, 1)
    } else {
      this.form.user_ids.push(user.id)
    }
  }

  private async submit(): Promise<void> {
    this.isSubmitting = true
    try {
      await this.entity.attachUsers(this.form)
      this.$emit('submit')
    } finally {
      this.isSubmitting = false
    }
  }

  private close(): void {
    this.$emit('close')
  }
}
